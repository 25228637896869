import { GetterTree, ActionTree, MutationTree } from "vuex";
import { EnteredValues } from '@/types/Keyboard';
import i18n from '@/i18n';
import { initialize } from '@/services/terminal.service';
import { gerErrorMessage } from '@/assets/ts/helpers';
import { InitResult } from '@/types/Init';
import {StateInterface} from "@/store/index";

export interface AppStateInterface {
  language: string,
  keyboardType: string,
  currentGroup: string,
  token: string,
  initError: string,
  serverTime: null | Date,
  path: string[],
  currentStep: number,
  pageIndent: number,
  enteredValues: EnteredValues,
  isSseError: boolean,
  isEditMode: boolean,
  isAgree: boolean,
  initResult?: InitResult
}
const state = ():AppStateInterface => ({
  language: 'ua' as string,
  keyboardType: '',
  currentGroup: '',
  token: '',
  initError: '',
  serverTime: null,
  path: [] ,
  enteredValues: {},
  currentStep: 0,
  pageIndent: 0,
  isSseError: false,
  isEditMode: false,
  isAgree: true,
});


const mutations: MutationTree<AppStateInterface> = {
  SET_LANGUAGE: (state, language: string) => {
    state.language = language;
    i18n.global.locale.value = language;
  },
  SET_CURRENT_GROUP: (state, group: string) => {
    state.currentGroup = group;
  },
  SET_IS_AGREE: (state, isAgree: boolean) => {
    state.isAgree = isAgree;
  },
  SET_TOKEN: (state, token: string) => {
    state.token = token;
  },
  ADD_PATH_ITEM: (state, path: string) => {
    if (state.path[state.path.length - 1] !== path && !!path) {
      console.log(`ADD_PATH_ITEM: --${ path }--`);
      state.path.push(path);
    }
  },
  REMOVE_LAST_PATH_ITEM: (state) => {
    console.log(`REMOVE_PATH_ITEM`);
    state.path.pop();
  },
  SET_LAST_PATH_ITEM: (state, pathItem: string) => {
    const index = state.path.indexOf(pathItem);
    if (index >= 0) {
      console.log(`SET_LAST_PATH_ITEM: --${ pathItem }--`);
      state.path = state.path.slice(0, index + 1);
    }
  },
  CLEAR_PATH: (state) => {
    state.path = [];
  },
  SET_ENTERED_VALUE: (state, { key, value }) => {
    state.enteredValues[key] = value;
  },
  CLEAR_ENTERED_VALUES: (state) => {
    state.enteredValues = {} as EnteredValues;
  },
  SET_CURRENT_STEP: (state, step: number) => {
    state.currentStep = step;
  },
  SET_PAGE_INDENT: (state, indent: number) => {
    state.pageIndent = indent;
  },
  CLEAR_USER_DATA: (state, lang: string) => {
    state.currentStep = 0;
    state.enteredValues = {} as EnteredValues;
    state.path = [];
    state.language = lang;
    state.keyboardType = lang;
  },
  SET_KEYBOARD_TYPE: (state, type: string) => {
    state.keyboardType = type;
  },
  SET_SSE_ERROR: (state, isError: boolean) => {
    state.isSseError = isError;
  },
  SET_INIT_ERROR: (state, error: string) => {
    state.initError = error;
  },
  SET_EDIT_MODE: (state, isEdit: boolean) => {
    state.isEditMode = isEdit;
  },
  SET_INIT_RESULT: (state, result: InitResult) => {
    state.initResult = result;
  },
  SET_SERVER_TIME: (state, serverTime: Date) => {
    state.serverTime = serverTime;
  }
};

const actions: ActionTree<AppStateInterface, StateInterface> = {
  async initialize({ commit }) {
    try {
      const res = await initialize();
      commit('SET_TOKEN', res?.token);
      commit('SET_INIT_ERROR', res?.error_message ?? res?.error_name ?? '');
      commit('SET_INIT_RESULT', res);
    } catch (err) {
      commit('SET_INIT_ERROR', gerErrorMessage(err));
      commit('SET_INIT_RESULT', {});
    }
  },
  setLanguage({ commit }, language: string) {
    commit('SET_LANGUAGE', language);
    commit('SET_KEYBOARD_TYPE', language);
  },
  addPathItem({ commit }, path: string) {
    commit('ADD_PATH_ITEM', path);
  },
  setLastPathItem({ commit }, path: string) {
    commit('SET_LAST_PATH_ITEM', path);
  },
  removeLastPathItem({ commit }) {
    commit('REMOVE_LAST_PATH_ITEM');
  },
  clearPath({ commit }) {
    commit('CLEAR_PATH');
  },
  setEnteredValue({ commit }, { key, value }) {
    commit('SET_ENTERED_VALUE', { key, value });
  },
  clearEnteredValues({ commit }) {
    commit('CLEAR_ENTERED_VALUES');
  },
  setCurrentStep({ commit }, step: number) {
    commit('SET_CURRENT_STEP', step);
  },
  setPageIndent({ commit }, indent: number) {
    commit('SET_PAGE_INDENT', indent);
  },
  setCurrentGroup({ commit }, group: string) {
    commit('SET_CURRENT_GROUP', group);
  },
  clearUserData({ commit, rootGetters }) {
    commit('CLEAR_USER_DATA', rootGetters.info?.settings?.language ?? localStorage.getItem('locale') ?? 'ua');
  },
  setKeyboardType({ commit }, type: string) {
    commit('SET_KEYBOARD_TYPE', type);
  },
  setSseError({ commit }, isError: boolean) {
    commit('SET_SSE_ERROR', isError);
  },
  setEditMode({ commit }, isEdit: boolean) {
    commit('SET_EDIT_MODE', isEdit);
  },
  setIsAgree({ commit }, isAgree: boolean) {
    commit('SET_IS_AGREE', isAgree);
  },
  setToken({ commit }, token: string) {
    commit('SET_TOKEN', token);
  },
  setInitResult({ commit }, result: InitResult) {
    commit('SET_INIT_RESULT', result);
  },
  setInitError({ commit }, error) {
    commit('SET_INIT_ERROR', error);
  },
  setServerTime({ commit }, serverTime) {
    commit('SET_SERVER_TIME', serverTime);
  }
};

const getters: GetterTree<AppStateInterface, StateInterface> = {
  language: ({ language }) => language,
  path: ({ path }) => path,
  enteredValues: ({ enteredValues }) => enteredValues,
  currentStep: ({ currentStep }) => currentStep,
  pageIndent: ({ pageIndent }) => pageIndent,
  keyboardType: ({ keyboardType }) => keyboardType,
  isSseError: ({ isSseError }) => isSseError,
  isEditMode: ({ isEditMode }) => isEditMode,
  currentGroup: ({ currentGroup }) => currentGroup,
  isAgree: ({ isAgree }) => isAgree,
  token: ({ token }) => token,
  initError: ({ initError }) => initError,
  initResult: ({ initResult }) => initResult,
  serverTime: ({ serverTime }) => serverTime,
};

export default {
  mutations,
  actions,
  getters,
  state
};
